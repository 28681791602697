import React from 'react';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  Grid,
  Button,
  ContentBox,
  HalvedContent,
  Paragraphs,
  List
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/tools-und-services';

const ToolsUndServicesPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
  >


    <section>
      <div className="container container--simple">
        <h3 className="-mb-8">{content.onlineTools.voGenerator.headline}</h3>
      </div>
      <ContentBox
        background="light-blue"
        headline={content.onlineTools.voGenerator.subHeadline}
        single
      >
        <p>{content.onlineTools.voGenerator.paragraph}</p>
        <Button
          href={content.onlineTools.voGenerator.button.href}
          variant="gray-blue"
          external
        >
          {content.onlineTools.voGenerator.button.text}
        </Button>
      </ContentBox>
      <div className="container container--simple">
        <h3 className="-mb-12">{content.onlineTools.optimierung.headline}</h3>
      </div>
      <ContentBox background="light-blue" single>
        <CardHeader
          headline={content.onlineTools.optimierung.card.headline}
          parallel
        >
          <content.onlineTools.optimierung.card.icon />
        </CardHeader>

        <p>{content.onlineTools.optimierung.card.paragraph}</p>
        <Button
          href={content.onlineTools.optimierung.card.button.href}
          variant="gray-blue"
          external
        >
          {content.onlineTools.optimierung.card.button.text}
        </Button>
      </ContentBox>
    </section>

    <section>
      <HalvedContent align="right" swapped>
        <BackgroundImage
          fluid={data.womanSmilingWithTablet.childImageSharp.fluid}
          className="grey-turquoise-box-shadow"
        />
        <ContentBox headline={content.mediathek.headline} wider>
          <Paragraphs items={content.mediathek.paragraphs} />
          <Link
            to={content.mediathek.link.href}
            className="arrow-link text-gray-blue arrow-gray-blue"
          >
            {content.mediathek.link.text}
          </Link>
        </ContentBox>
      </HalvedContent>
    </section>
    <section className="bg-light-blue">
      <SectionHead headline={content.links.headline}>
        <Paragraphs items={content.links.paragraphs} />
      </SectionHead>
      <div className="container container--simple">
        <List items={content.links.listIitems} />
      </div>
    </section>
  </MainLayout>
);

export default ToolsUndServicesPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-leaning-on-window-with-coffee.jpg" }
    ) {
      ...image
    }
    womanSmilingWithTablet: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-smiling-with-tablet.jpg" }
    ) {
      ...image
    }
  }
`;
