import ExchangeEuroIcon from 'src/assets/icons/exchange-euro.svg';

const content = {
  title: 'Tools & Services',
  headvisualHeadline: 'Tools & Services',
  onlineTools: {
    headline: 'Online-Tools',
    optimierung: {
      headline: 'Was können Sie in der bAV noch optimieren?',
      card: {
        icon: ExchangeEuroIcon,
        headline: 'VWL optimieren',
        paragraph:
          'Erfahren Sie, welcher Betrag anstelle der VWL in eine Betriebsrente  investiert werden kann - ohne dass sich das Nettoeinkommen verändert.',
        button: {
          href: 'http://bavrechner.nuernberger.de/',
          text: 'Jetzt berechnen',
        },
      },
    },
    voGenerator: {
      headline:
        'Wie schaffen Sie die arbeitsrechtliche Grundlage, um die Vorteile für Ihre Mitarbeiter zu nutzen?',
      subHeadline: 'VO-Generator',
      paragraph:
        'Die NBB bietet in Zusammenarbeit mit renommierten Rechtsanwaltskanzlein bedarfsgerechte Lösungen und Dienstleistungen an, die Sie jederzeit online abrufen und in Auftrag geben können. ',
      button: {
        href: 'https://nbb-versorgungsordnung.digital/',
        text: 'Jetzt erstellen',
      },
    },
  },
  mediathek: {
    headline: 'Mediathek',
    paragraphs: [
      'Suchen Sie allgemeine Informationen oder eine bestimmte Präsentation?',
      'In unserer Mediathek finden Sie alle Unterlagen zu unseren Produkten und Dienstleistungen sowie fachliche Erklärungen.',
    ],
    link: {
      href: '/mediathek',
      text: 'Zur Mediathek',
    },
  },
  links: {
    headline: 'Nützliche Links',
    paragraphs: [
      'Sie benötigen noch mehr Informationen? Hier erhalten Sie weiterführende Links.',
      'Die aktuellen Merkblätter vom Pensions-Sicherungs-Verein Versicherungsverein a.G. (PSVaG) stehen Ihnen direkt auf der nachfolgend genannten Homepage zum Download bereit.',
    ],
    listIitems: [
      {
        href: 'https://www.nuernberger.de/',
        text: 'NÜRNBERGER Versicherung',
      },
      {
        href: 'https://www.ihk.de/',
        text: 'Deutscher Industrie- und Handelskammertag e.V. (DIHK)',
      },
      {
        href: 'https://www.datev.de/web/de/startseite startseite-n/',
        text: 'DATEV eG',
      },
      {
        href: 'https://nuernberger-manager.xempus.com/',
        text: 'Digitales bAV-Verwaltungsportal: XEMPUS',
      },
      {
        href: 'https://www.psvag.de/',
        text: 'Pensions-Sicherungs-Verein Versicherungsverein a.G. (PSVaG)',
      },
    ],
  },
};

export default content;
